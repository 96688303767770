import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  name: "smsstaticTemplateList",

  data() {
    return {
      smsstaticTemplateList: [],
      searchText: "",
      showLoader: false,
      type: "internal",
      selected: "",
      selectedGroup: "",
      selectedId: 0,
      selectedRows: [],
      groupList: [],
      sortColumn: "name",
      sortDirection: "asc",
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.smsstaticTemplateList.length > 0) {
        return (
          _.where(this.smsstaticTemplateList, { isSelected: true }).length ===
          this.smsstaticTemplateList.length
        );
      }
      return false;
    },
    hasSmsStaticTempleShowPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadSmsStaticTemplate-index";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSmsStaticTempletAddPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadSmsStaticTemplate-store";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSmsStaticTempletUpdatePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadSmsStaticTemplate-update";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },
  methods: {
    getGroup() {
      let _vm = this;
      this.axios
        .get("/sms-static-templates-groups")
        .then(function (response) {
          _vm.groupList = response.data.data;
        })
        .catch(function () {});
    },
    formatString(input) {
      // Split the string by underscore, capitalize each word, and join them back with a space
      return input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },

    checkUncheckedList(event) {
      this.smsstaticTemplateList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    search(value) {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&type=" +
        this.type +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.selectedGroup !== "" ? "&group=" + this.selectedGroup : "";
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Send a GET request to retrieve tags
      this.axios
        .get("/sms-static-templates" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.smsstaticTemplateList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
  },
  mounted() {
    this.getAll();
    this.getGroup();
  },
};
